import React, { useContext, useEffect, useState } from 'react';
import SingleItem from '../SingleItem';
import axios from "axios";
import { RotatingSquare } from "react-loader-spinner";
import CustomPagination from "../../../CustomPagination/CustomPagination";
import { AuthContext } from '../../../../Contexts/AuthContext/AuthProvider';

const AllUsers = () => {
    const { authUser } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [paginateData, setPaginateData] = useState([]);

    const [data, setData] = useState([]);
    useEffect(() => {
        fetch(`https://api2.prospectstreetx.com/api/admin/user/view/all`, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setData(data.data)
                setPaginateData(data);
                setLoading(false);
            })
    }, []);

    const handlePage = (event) => {
        try {
            const pageNumber = event?.selected + 1;
            const config = {
                headers: {
                    "content-type": "application/json",
                },
            };
            axios
                .get(
                    `https://api2.prospectstreetx.com/api/admin/user/view/all?page=${pageNumber}`,
                    config
                )
                .then((response) => {
                    setData(response?.data?.data);
                    setPaginateData(response?.data);
                })
                .catch((error) => { });
        } catch (error) {
            console.log(error);
        }
    };
    if (!(authUser?.manage_users)) {
        if (authUser?.manage_users === undefined) {
            return (
                <>
                    <div className="loader-css">
                        <RotatingSquare
                            visible={true}
                            height="100"
                            width="100"
                            color="#4fa94d"
                            ariaLabel="rotating-square-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <h1>unauthorized</h1>
                </>
            );
        }



    } else {
        if (loading) {
            return (
                <>
                    <div className="loader-css" >
                        <RotatingSquare
                            visible={true}
                            height="100"
                            width="100"
                            color="#4fa94d"
                            ariaLabel="rotating-square-loading"
                            wrapperStyle={{}
                            }
                            wrapperClass=""
                        />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div class="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
                        <h6 class="page-title">All Users</h6>
                        
                    </div>
    
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card b-radius--10 ">
                                <div class="card-body p-0">
                                    <div class="table-responsive--md  table-responsive">
                                        <table class="table table--light style--two">
                                            <thead>
                                                <tr>
                                                    <th>Full Name</th>
                                                    <th>Email</th>
                                                    <th>Phone</th>
                                                    <th>Password</th>
                                                    <th>Country</th>
                                                    <th>Joined At</th>
                                                    <th>Balance</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.length !== 0 ?
                                                    data.map((data, index) => {
                                                        if (data) {
                                                            return (
                                                                <SingleItem data={data} index={index} key={data._id} paginateData={paginateData}></SingleItem>
                                                            );
                                                        }
                                                    }) :
                                                    <tr>
                                                        <td className="text-muted text-center" colspan="100%">Data not found</td>
                                                    </tr>}
    
    
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* paginateLinks */}
                                <CustomPagination
                                    data={paginateData}
                                    handlePage={handlePage}
                                > </CustomPagination>
                                {/* paginateLinks */}
                            </div>
                        </div>
    
                    </div>
                </>
            );
    
        }

    }
   


};

export default AllUsers;