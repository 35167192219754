import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import dateFormat from "dateformat";
const SingleItem = ({ data, index, paginateData }) => {

    const [userData, setuserData] = useState([]);
    useEffect(() => {
        if(data?.user_id){
            fetch(`https://nahid-crypto-server-production-a832.up.railway.app/api/admin/user/view/single/${data?.user_id}`, {
                method: 'GET',
            })
                .then((res) => res.json())
                .then((data) => {
                    setuserData(data.data)
                })
        }
     
    }, []);


    return (
        <>
            <tr>
                <td data-label="S.N.">{(parseFloat(index + 1 ) + ( parseFloat(paginateData?.page ) * parseFloat(paginateData?.limit ) )  ) - 10}</td>
                <td data-label="Full Name">
                    <Link to={`/admin/users/details/${userData?._id}`}>
                    <span className="d-block">{userData?.fname} {userData?.lname}</span>
                    </Link>
                   
                </td>
                <td data-label="Promotion Name">
                    <span>{data.promotion_name}</span> 
                </td>
                <td data-label="Amount">{data?.amount} $</td>
                <td data-label="Profit Amount">{data?.profit_amount} $ </td>
                <td data-label="Period">
                {data?.period} days
                </td>
               
                <td data-label="Expired Time">
                    {data?.expired_time}

                </td>
                <td data-label="Status">

                {data.status === 0
                        ?
                        <span className="badge badge--danger text-danger">Running</span>         
                        :
                        ''
                    }
                    {data.status === 1
                        ?
                        <span className="badge badge--success text-success">Complete</span>
                        :
                        ''
                    }
                    
                   
                </td>
                <td data-label="Date"> {dateFormat(data?.createdAt, "d-m-yyyy h:MM:ss TT")}</td>
            </tr>
        </>
    );
};

export default SingleItem;