import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
const SingleItem = ({ data, index, handleEnableDisabled, paginateData, handleDelete }) => {
  return (
    <>
      <tr data-label="S.N.">
        <td>
          {parseFloat(index + 1) +
            parseFloat(paginateData?.page) * parseFloat(paginateData?.limit) -
            10}
        </td>

        <td data-label="Image">
          <div className="user">
            <div className="thumb">
              <img src={`https://api2.prospectstreetx.com/${data?.image}`} alt="" />
            </div>
          </div>
        </td>

        <td data-label="Gateway">
          <span className="d-block"> {data?.networkType}</span>
        </td>
        <td data-label="Wallet Address">
          <span className="d-block"> {data?.wallet_address}</span>
        </td>
        <td data-label="Status">
          {data?.status ? (
            <span class="badge badge--success text-success">Enabled</span>
          ) : (
            <span>
              <span class="badge badge--warning text-warning">Disabled</span>
            </span>
          )}
        </td>
        <td data-label="Active">
          <div class="button-group">
            <Link
              to={`/admin/gateway/manual/edit/${data._id}`}
              class="btn btn-sm btn-outline-primary editGatewayBtn"
            >
              <i class="la la-pencil"></i> Edit
            </Link>
            {data?.status ? (
              <button
                onClick={() => handleEnableDisabled(data?._id)}
                class="btn btn-sm btn-outline-danger confirmationBtn"
              >
                <i class="la la-eye-slash"></i> Disable{" "}
              </button>
            ) : (
              <button
                onClick={() => handleEnableDisabled(data?._id)}
                class="btn btn-sm btn-outline-success confirmationBtn"
              >
                <i class="la la-eye"></i> Enable{" "}
              </button>
            )}

<button onClick={() => handleDelete(data?._id)} className="btn btn-sm btn-outline-danger ms-1 confirmationBtn"
                    >
                       <FontAwesomeIcon icon="fa-solid fa-trash-can" />Delete
                    </button>
          </div>
        </td>
      </tr>
    </>
  );
};

export default SingleItem;
