import React, { useEffect, useRef, useState } from 'react';
import {  toast } from 'react-toastify';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const FAQEdit = () => {
   const {id} = useParams();
    const [results, setResults] = useState({});
    const [dataVulue, setdataVulue] = useState({});
    const refSubmitDis = useRef();

    const handleSubmitData = event => {
        event.preventDefault();
        refSubmitDis.current.setAttribute("disabled", true);
        fetch(`https://api2.prospectstreetx.com/api/admin/faq/update/${id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(dataVulue)
        })
            .then(res => res.json())
            .then(data => {
                if (data.success === false) {
                } else {
                    toast.success(`${data.message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    refSubmitDis.current.removeAttribute("disabled");
                    setResults(data);
                    event.target.reset();
                }
            })
            .catch(error =>
                refSubmitDis.current.removeAttribute("disabled")
            );
    }

    const handleInputBlur = event => {
        const value = event.target.value;
        const field = event.target.name;
        const newData = { ...dataVulue };
        newData[field] = value;
        setdataVulue(newData);
    }

    const [data, setData] = useState([]);
    useEffect(() => {
        fetch(`https://api2.prospectstreetx.com/api/admin/faq/view/${id}`, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => setData(data.data))
    }, [results])



   

        return (
            <>
                <div className="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
                    <h6 className="page-title">Blogs Section</h6>
                    
                </div>

                <div className="row">
                    <div className="col-lg-12 col-md-12 mb-30">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={handleSubmitData}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label for="sub_heading" className="required">Question</label>
                                                <input type="text" onBlur={handleInputBlur} className="form-control" name="question" defaultValue={data?.question} required="" id="sub_heading" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label for="sub_heading" className="required">Answer</label>
                                                <input type="text" onBlur={handleInputBlur} className="form-control" name="answer" defaultValue={data?.answer} required="" id="sub_heading" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button ref={refSubmitDis} type="submit" className="btn btn-primary w-100 h-45">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                
            </>
        );

};

export default FAQEdit;