import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import AuthProvider from './Contexts/AuthContext/AuthProvider';
import 'react-loading-skeleton/dist/skeleton.css';
const root = createRoot(document.getElementById("root"))
root.render
  (
    <AuthProvider>
      <App />
    </AuthProvider>
  )