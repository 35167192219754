import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { RotatingSquare } from 'react-loader-spinner';
import { Link, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const NoticesEdit = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [results, setResults] = useState({});
    const [dataVulue, setDataVulue] = useState({});
    const refSubmitDis = useRef();

    const [userImage, setUserImage] = useState('');

    const handleImage = (e) => {
        setUserImage(e.target.files[0])

    }

    const handleSubmitData = event => {
        event.preventDefault();
        refSubmitDis.current.setAttribute("disabled", true);

        if (userImage) {
            const userData = { ...dataVulue, image: userImage };

            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                }
            };
            axios.put(`https://api2.prospectstreetx.com/api/admin/home/notice/update/${id}`, userData, config)
                .then(response => {
                    event.target.reset();
                    toast.success(`${response?.data.message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setResults(response)
                    refSubmitDis.current.removeAttribute("disabled");
                })
                .then(data => {
                    toast.success(`${data.message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }).catch((error) => {
                    refSubmitDis.current.removeAttribute("disabled");
                });
        } else {
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                }
            };
            axios.put(`https://api2.prospectstreetx.com/api/admin/home/notice/update/${id}`, dataVulue, config)
                .then(response => {
                    event.target.reset();
                    toast.success(`${response?.data.message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setResults(response)
                    refSubmitDis.current.removeAttribute("disabled");
                })
                .then(data => {
                    toast.success(`${data.message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }).catch((error) => {
                    refSubmitDis.current.removeAttribute("disabled");
                });
        }



    }


    const handleInputBlur = event => {
        const value = event.target.value;
        const field = event.target.name;
        const newData = { ...dataVulue };
        newData[field] = value;
        setDataVulue(newData);
    }

    const [data, setData] = useState([]);
    useEffect(() => {
        fetch(`https://api2.prospectstreetx.com/api/admin/home/notice/edit/${id}`, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setData(data.data[0]);
                setLoading(false);
            })
    }, [results])
    // {data?.title_one}

    if (loading) {
        return (
            <>
                <div className="loader-css" >
                    <RotatingSquare
                        visible={true}
                        height="100"
                        width="100"
                        color="#4fa94d"
                        ariaLabel="rotating-square-loading"
                        wrapperStyle={{}
                        }
                        wrapperClass=""
                    />
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
                    <h6 className="page-title">Notice Update</h6>
                    <div className="d-flex flex-wrap justify-content-end gap-2 align-items-center breadcrumb-plugins">
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 col-md-12 mb-30">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={handleSubmitData}>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Image</label>
                                                <div className="image-upload">
                                                    <div className="thumb">
                                                        <div className="avatar-preview">
                                                            <div className="profilePicPreview" style={{ height: '120px', }}>
                                                                <img src={`https://api2.prospectstreetx.com/${data?.image}`} alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="avatar-edit">
                                                            <input type="file" onChange={handleImage} className="profilePicUpload" name="image" id="profilePicUpload0" accept=".jpg, .png, .jpeg" />
                                                            <label htmlFor="profilePicUpload0" className="bg--primary">Image</label>
                                                            <small class="mt-2  ">Supported files: <b>jpeg, jpg, png</b>.
                                                                | Will be resized to:
                                                                <b>600x800</b>
                                                                px.
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" col-md-8 ">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="heading" className="required">Name</label>
                                                    <input type="text" className="form-control" name="name" onBlur={handleInputBlur} defaultValue={data?.name} required="" id="heading" />
                                                </div>
                                            </div>



                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <button ref={refSubmitDis} type="submit" className="btn btn-primary w-100 h-45">Update</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

};

export default NoticesEdit;