import React, { useContext, useEffect, useState } from 'react';
import SingleItem from './SingleItem';
import { toast } from 'react-toastify';
import { useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { RotatingSquare } from 'react-loader-spinner';
import { AuthContext } from '../../../../Contexts/AuthContext/AuthProvider';

const ManagePages = () => {
    const { authUser } = useContext(AuthContext);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [updateData, setupdateData] = useState([]);
    useEffect(() => {
        fetch(`https://api2.prospectstreetx.com/api/frontend/home/menu/view`, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setData(data.data)
                setLoading(false);
            })
    }, [updateData])


    const handleDelete = id => {
        fetch(`https://api2.prospectstreetx.com/api/admin/home/menu/delete/${id}`, {
            method: 'DELETE',
            headers: {
                'content-type': 'application/json',
            },
        })
            .then(res => res.json())
            .then(data => {
                setupdateData(data);
                toast.error(`Delete succssfull`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
            .catch(error => console.log(error));
    }


    const [dataVulue, setDataVulue] = useState({});
    const refSubmitDis = useRef();


    const handleSubmitData = event => {
        event.preventDefault();
        refSubmitDis.current.setAttribute("disabled", true);
        fetch(`https://api2.prospectstreetx.com/api/admin/home/menu/store`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(dataVulue)
        })
            .then(res => res.json())
            .then(data => {
                if (data.success === false) {
                    toast.error(`${data.message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    refSubmitDis.current.removeAttribute("disabled")
                } else {
                    toast.success(`${data.message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setupdateData(data);
                    handleClose();
                    refSubmitDis.current.removeAttribute("disabled")
                }
            })
            .catch(error => console.log(error));
    }

    const handleInputBlur = event => {
        const value = event.target.value;
        const field = event.target.name;
        const newData = { ...dataVulue };
        newData[field] = value;
        setDataVulue(newData);
    }
    if (!(authUser?.manage_page)) {
        if (authUser?.manage_page === undefined) {
            return (
                <>
                    <div className="loader-css">
                        <RotatingSquare
                            visible={true}
                            height="100"
                            width="100"
                            color="#4fa94d"
                            ariaLabel="rotating-square-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <h1>unauthorized</h1>
                </>
            );
        }



    } else {
        if (loading) {
            return (
                <>
                    <div className="loader-css" >
                        <RotatingSquare
                            visible={true}
                            height="100"
                            width="100"
                            color="#4fa94d"
                            ariaLabel="rotating-square-loading"
                            wrapperStyle={{}
                            }
                            wrapperClass=""
                        />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div class="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
                        <h6 class="page-title">Manage Pages</h6>
                        <div class="d-flex flex-wrap justify-content-end gap-2 align-items-center breadcrumb-plugins">
                            <button onClick={handleShow} class="btn btn-sm btn-outline-primary addBtn"><i class="las la-plus"></i>Add New</button>
                        </div>
                    </div>
    
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card b-radius--10 ">
                                <div class="card-body p-0" >
                                    <div class="table-responsive--sm table-responsive">
                                        <table class="table table--light style--two custom-data-table">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Slug</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.length !== 0 ?
                                                    data.map((data, index) => {
                                                        if (data) {
                                                            return (
                                                                <SingleItem data={data} index={index} key={data._id} handleDelete={handleDelete}></SingleItem>
                                                            );
                                                        }
                                                    }) :
                                                    <tr>
                                                        <td className="text-muted text-center" colspan="100%">Data not found</td>
                                                    </tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add Page</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form onSubmit={handleSubmitData}>
                                <div class="modal-body">
                                    <div class="form-group mb-5">
                                        <label htmlFor="image" class="required">Name</label>
                                        <input type="text" onBlur={handleInputBlur} className="form-control" name="name" required id="heading" />
                                    </div>
                                    <div class="form-group mb-5">
                                        <label htmlFor="image" class="required">Slug</label>
                                        <input type="text" onBlur={handleInputBlur} className="form-control" name="slug" required id="heading" />
                                    </div>
                                    <button type="submit" ref={refSubmitDis} class="btn btn-primary w-100 h-45">Submit</button>
                                </div>
    
                            </form>
    
                        </Modal.Body>
                    </Modal>
                </>
            );
        }

    }
   

};

export default ManagePages;