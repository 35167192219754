import React, { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { RotatingSquare } from "react-loader-spinner";
import { AuthContext } from '../../../../Contexts/AuthContext/AuthProvider';
import SingleItem from './SingleItem';
import CustomPagination from '../../../CustomPagination/CustomPagination';
import { Modal } from 'react-bootstrap';

const News = () => {
    const { authUser } = useContext(AuthContext);
   
    const [loading, setLoading] = useState(true);
    const [paginateData, setPaginateData] = useState([]);
    const [data, setData] = useState([]);
    const [updateData, setupdateData] = useState([]);


    useEffect(() => {
        fetch(`https://api2.prospectstreetx.com/api/admin/news/view`, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setData(data.data)
                setPaginateData(data);
                setLoading(false);
            })
    }, [updateData]);

    const handlePage = (event) => {
        try {
            const pageNumber = event?.selected + 1;
            const config = {
                headers: {
                    "content-type": "application/json",
                },
            };
            axios
                .get(
                    `https://api2.prospectstreetx.com/api/admin/news/view?page=${pageNumber}`,
                    config
                )
                .then((response) => {
                    setData(response?.data?.data);
                    setPaginateData(response?.data);
                })
                .catch((error) => { });
        } catch (error) {
            console.log(error);
        }
    };


    const handleDelete = id => {
        fetch(`https://api2.prospectstreetx.com/api/admin/news/delete/${id}`, {
            method: 'DELETE',
            headers: {
                'content-type': 'application/json',
            },
        })
            .then(res => res.json())
            .then(data => {
                toast.error(`${data.message}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setupdateData(data);
            })
            .catch(error => console.log(error));
    }


    const handleEnableDisabled = (id) => {
        fetch(
          `https://api2.prospectstreetx.com/api/admin/news/enable/disable/${id}`,
          {
            method: "PUT",
            headers: {
              "content-type": "application/json",
            },
          }
        )
          .then((res) => res.json())
          .then((data) => {
            setupdateData(data);
            if (data?.data?.status) {
              toast.success(`${data.message}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }else{
                toast.error(`${data.message}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  });
            }
            
          })
          .catch((error) => console.log(error));
      };

      

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [results, setResults] = useState({});
    const [dataVulue, setDataVulue] = useState({});
    const refSubmitDis = useRef();

      // Data Store 

    const handleSubmitData = event => {
        event.preventDefault();
        refSubmitDis.current.setAttribute("disabled", true);
        const config = {
            headers: {
                'content-type': 'application/json',
            }
        };
        axios.post(`https://api2.prospectstreetx.com/api/admin/news/category/store`, dataVulue, config)
            .then(response => {
                if(response?.data?.success === false){
                    toast.error(`${response?.data.message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    refSubmitDis.current.removeAttribute("disabled");
                }else{
                    event.target.reset();
                    setDataVulue({})
                    setShow(false)
                    toast.success(`${response?.data.message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setResults(response?.data)
                }
              
                refSubmitDis.current.removeAttribute("disabled");
            }).catch((error) => {
                refSubmitDis.current.removeAttribute("disabled");
            });

    }


    const handleInputBlur = event => {
        setResults({});
        const value = event.target.value;
        const field = event.target.name;
        const newData = { ...dataVulue };
        newData[field] = value;
        setDataVulue(newData);
    }



    if (!(authUser?.manage_section)) {
        if (authUser?.manage_section === undefined) {
            return (
                <>
                    <div className="loader-css">
                        <RotatingSquare
                            visible={true}
                            height="100"
                            width="100"
                            color="#4fa94d"
                            ariaLabel="rotating-square-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <h1>unauthorized</h1>
                </>
            );
        }



    } else {
        if (loading) {
            return (
                <>
                    <div className="loader-css" >
                        <RotatingSquare
                            visible={true}
                            height="100"
                            width="100"
                            color="#4fa94d"
                            ariaLabel="rotating-square-loading"
                            wrapperStyle={{}
                            }
                            wrapperClass=""
                        />
                    </div>
                </>
            );
        } else {
    
            return (
                <>
                    <div className="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
                        <h6 className="page-title">News Section</h6>
                        <div className="d-flex flex-wrap justify-content-end gap-2 align-items-center breadcrumb-plugins">
                            <div className="d-flex flex-wrap justify-content-end gap-2 align-items-center breadcrumb-plugins">
                                <Link
                                    to="/admin/news/add"
                                    className="btn btn-sm btn-outline-primary addBtn"
                                >
                                    <i className="fa-solid fa-list-ul me-1"></i>
                                  Add News
                                </Link>
    
                                <button
                                    onClick={handleShow}
                                    className="btn btn-sm btn-outline-primary addBtn"
                                >
                                    <i className="fa-solid fa-list-ul me-1"></i>Add Category
                                </button>
                                <Link
                                    to="/admin/news/category/view"
                                    className="btn btn-sm btn-outline-primary addBtn"
                                >
                                    <i className="fa-solid fa-list-ul me-1"></i>All Category
                                </Link>
                               
                            </div>
                        </div>
                    </div>
    
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body p-0" >
                                <div className="table-responsive--sm table-responsive">
                                    <table className="table table--light style--two custom-data-table">
                                        <thead>
                                            <tr>
                                                <th>SL</th>
                                                <th>Category</th>
                                                <th>News Title</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className="list">
                                            {data.length !== 0 ?
                                                data.map((data, index) => {
                                                    if (data) {
                                                        return (
                                                            <SingleItem data={data} index={index} key={data._id} handleDelete={handleDelete} paginateData={paginateData} handleEnableDisabled={handleEnableDisabled}></SingleItem>
                                                        );
                                                    }
                                                }) :
                                                <tr>
                                                    <td className="text-muted text-center" colspan="100%">Data not found</td>
                                                </tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            {/* paginateLinks */}
                            <CustomPagination
                                data={paginateData}
                                handlePage={handlePage}
                            > </CustomPagination>
                            {/* paginateLinks */}
                        </div>
                    </div>
                </div>


                 {/* Modal  */}
    
                 <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add Category</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form onSubmit={handleSubmitData}>
                                    <div className="modal-body">
                                        <div className="form-group">
                                            <label htmlFor="name" className="required">Category Name</label>
                                            <input type="text" className="form-control" name="name" onBlur={handleInputBlur} required="" id="name" />
                                        </div>
                                    
                                        <button type="submit" ref={refSubmitDis} className="btn btn-primary w-100 h-45">Submit</button>
                                    </div>
    
                                </form>
    
                            </Modal.Body>
                        </Modal>
    
                </>
            );
        }

    }
  

  

};
export default News;