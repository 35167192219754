import React, { useContext, useRef, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { RotatingSquare } from "react-loader-spinner";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
const ManualGatewayAdd = () => {
  const { authUser } = useContext(AuthContext);

  const [dataVulue, setDataVulue] = useState({});
  const refSubmitDis = useRef();

  const [userImage, setUserImage] = useState("");

  const handleImage = (e) => {
    setUserImage(e.target.files[0]);
  };

  const handleSubmitData = (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);
    const userData = { ...dataVulue, image: userImage };
    if (userData.image === "") {
      toast.error(`please choose your qr code image`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      refSubmitDis.current.removeAttribute("disabled");
    } else {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      axios
        .post(
          `https://api2.prospectstreetx.com/api/admin/gateway/manual/store`,
          userData,
          config
        )
        .then((response) => {
          event.target.reset();
          toast.success(`${response?.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          refSubmitDis.current.removeAttribute("disabled");
        })
        .then((data) => {
          toast.success(`${data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((error) => {
          refSubmitDis.current.removeAttribute("disabled");
        });
    }
  };

  const handleInputBlur = (event) => {
    const value = event.target.value;
    const field = event.target.name;
    const newData = { ...dataVulue };
    newData[field] = value;
    setDataVulue(newData);
  };

  if (!authUser?.payment_gateways) {
    if (authUser?.payment_gateways === undefined) {
      return (
        <>
          <div className="loader-css">
            <RotatingSquare
              visible={true}
              height="100"
              width="100"
              color="#4fa94d"
              ariaLabel="rotating-square-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <h1>unauthorized</h1>
        </>
      );
    }
  } else {
    return (
      <>
        <div className="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
          <h6 className="page-title">Diposit Gateway</h6>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card mb-4">
              <form onSubmit={handleSubmitData}>
                <div className="card-body">
                  <div className="payment-method-item">
                    <div className="payment-method-body">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>QR Code Image</label>
                            <div className="image-upload">
                              <div className="thumb">
                                <div className="avatar-edit">
                                  <input
                                    type="file"
                                    onChange={handleImage}
                                    className="profilePicUpload"
                                    name="event_img"
                                    id="profilePicUpload0"
                                    accept=".jpg, .png, .jpeg"
                                    required
                                  />
                                  <label
                                    htmlFor="profilePicUpload0"
                                    className="bg--primary"
                                  >
                                    Image
                                  </label>
                                  <small class="mt-2  ">
                                    Supported files: <b>jpeg, jpg, png</b>. |
                                    Will be resized to:
                                    <b>800x800</b>
                                    px.
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" col-md-8 ">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="heading" className="required">
                                Network Type
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="networkType"
                                onBlur={handleInputBlur}
                                required
                                id="heading"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="heading" className="required">
                                Wallet Address
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="wallet_address"
                                onBlur={handleInputBlur}
                                required
                                id="heading"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div className="card border--primary mt-3">
                            <h5 className="card-header bg--primary">Range</h5>
                            <div className="card-body">
                              <div className="form-group">
                                <label htmlFor="min_limit" className="required">
                                  Minimum Amount
                                </label>
                                <div className="input-group">
                                  <input
                                    type="number"
                                    step="any"
                                    className="form-control"
                                    name="MinimumAmount"
                                    required
                                    min="0"
                                    onBlur={handleInputBlur}
                                    id="min_limit"
                                  />
                                  <div className="input-group-text">USD</div>
                                </div>
                              </div>
                              <div className="form-group">
                                <label htmlFor="max_limit" className="required">
                                  Maximum Amount
                                </label>
                                <div className="input-group">
                                  <input
                                    type="number"
                                    step="any"
                                    className="form-control"
                                    required
                                    id="max_limit"
                                    name="MaximumAmount"
                                    min="0"
                                    onBlur={handleInputBlur}
                                  />
                                  <div className="input-group-text">USD</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div className="card border-primary mt-3">
                            <h5 className="card-header bg--primary">Charge</h5>
                            <div className="card-body">
                              <div className="form-group">
                                <label
                                  htmlFor="percent_charge"
                                  className="required"
                                >
                                  Percent Charge
                                </label>
                                <div className="input-group">
                                  <input
                                    type="number"
                                    step="any"
                                    className="form-control"
                                    name="PercentCharge"
                                    min="0"
                                    onBlur={handleInputBlur}
                                    required
                                    id="percent_charge"
                                    placeholder="Percent Charge"
                                  />
                                  <div className="input-group-text">%</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <button
                    type="submit"
                    ref={refSubmitDis}
                    className="btn btn-primary w-100 h-45"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
};
export default ManualGatewayAdd;
