import React from 'react';

const SingleItem = () => {
    return (
        <>
            
        </>
    );
};

export default SingleItem;