import React from 'react';
import { Link } from 'react-router-dom';

const SingleItem = ({ index, data, handleDelete, paginateData, handleEnableDisabled }) => {
    return (
        <>
            <tr>
                <td data-label="S.N.">{ (parseFloat(index + 1) + (parseFloat(paginateData?.page) * parseFloat(paginateData?.limit))) - 10 }</td>
          
                <td data-label="Category">{data?.category}</td>
                <td data-label="News Title">{data?.title}</td>
                <td data-label="Action">
                    <div className="button--group">
                        <Link to={`/admin/news/update/${data?._id}`} className="btn btn-sm btn-outline-primary"><i className="la la-pencil-alt"></i> Edit</Link>
                        {data?.status  ?
                        <button onClick={() => handleEnableDisabled(data?._id)} className="btn btn-sm btn-outline-danger ms-1 confirmationBtn"
                        >
                            <i className="la la-eye-slash"></i>Disable
                        </button>
                        :

                        <button onClick={() => handleEnableDisabled(data?._id)} className="btn btn-sm btn-outline-success ms-1 confirmationBtn"
                        >
                            <i className="la la-eye"></i> Enable
                        </button>
                    }
                        <button className="btn btn-sm btn-outline-danger " onClick={()=>handleDelete(data?._id)} ><i className="la la-trash"></i> Remove</button>
                    </div>
                </td>
            </tr>

        </>
    );
};

export default SingleItem;