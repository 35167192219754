import React from 'react';
import dateFormat from "dateformat";
import { Link } from 'react-router-dom';
const SingleItem = ({ data, handleDelete }) => {
    return (
        <>
            <tr>
                <td data-label="Full Name">{data?.name}</td>
                <td data-label="Email">{data?.email}</td>
                <td data-label="Phone Number">{data?.phone}</td>
                <td data-label="Status">
                    {data.status === 0 ?
                        <span className="badge badge--warning text-warning">Pending</span>
                        : ''}
                    {data.status === 1 ?
                        <span className="badge badge--success text-success">Send Message</span>
                        : ''}
                </td>
                <td data-label="Subscribe At">{dateFormat(data.OutTime, "d-m-yyyy h:MM:ss TT")}</td>
                <td data-label="Action">
                    <Link to={`/admin/contact/us/details/${data?._id}`} className="btn btn-sm btn-outline-primary ms-1">
                        <i className="la la-desktop"></i> View                                    </Link>
                </td>
            </tr>

        </>
    );
};

export default SingleItem;