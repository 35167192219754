import React from "react";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SingleItem = ({
  index,
  data,
  handleDelete,
  paginateData,
  handleEnableDisabled,
}) => {
  return (
    <>
      <tr>
        <td data-label="S.N.">
          {parseFloat(index + 1) +
            parseFloat(paginateData?.page) * parseFloat(paginateData?.limit) -
            10}
        </td>

        <td data-label="Promotion Name">{data?.promotion_name}</td>

        <td data-label="Period">
          {data?.period} {data?.period_type}
        </td>

        <td data-label="Profit">{data?.percentage}%</td>
        <td data-label="Status">
          {data?.status ? (
            <span className="badge badge--success text-success">Enabled</span>
          ) : (
            <span>
              <span class="badge badge--warning text-warning">Disabled</span>
            </span>
          )}
        </td>
        <td data-label="Created">
          {dateFormat(data?.createdAt, "d-m-yyyy h:MM:ss TT")}
        </td>
        <td data-label="Action">
          <Link
            to={`/admin/investment/plan/update/${data._id}`}
            className="btn btn-sm btn-outline-primary editBtn"
          >
            <i className="la la-pencil"></i>Edit
          </Link>

          {data?.status ? (
            <button
              onClick={() => handleEnableDisabled(data._id)}
              className="btn btn-sm btn-outline-danger ms-1 confirmationBtn"
            >
              <i className="la la-eye-slash"></i>Disable
            </button>
          ) : (
            <button
              onClick={() => handleEnableDisabled(data._id)}
              className="btn btn-sm btn-outline-success ms-1 confirmationBtn"
            >
              <i className="la la-eye"></i> Enable
            </button>
          )}
          <button
            onClick={() => handleDelete(data._id)}
            className="btn btn-sm btn-outline-danger ms-1 confirmationBtn"
          >
            <FontAwesomeIcon icon="fa-solid fa-trash-can" />
            Delete
          </button>
        </td>
      </tr>
    </>
  );
};

export default SingleItem;
