import React, { useContext, useEffect, useState } from 'react';
import SingleItem from '../SingleItem';
import axios from "axios";
import { RotatingSquare } from "react-loader-spinner";
import CustomPagination from "../../../CustomPagination/CustomPagination";
import { AuthContext } from '../../../../Contexts/AuthContext/AuthProvider';

const SupportTicketPending = () => {
    const { authUser } = useContext(AuthContext);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paginateData, setPaginateData] = useState([]);
    useEffect(() => {
        fetch(`https://api2.prospectstreetx.com/api/admin/support/tickets/view`, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setData(data.data)
                setPaginateData(data);
                setLoading(false);
            })
    }, []);

    const handlePage = (event) => {
        try {
            const pageNumber = event?.selected + 1;
            const config = {
                headers: {
                    "content-type": "application/json",
                },
            };
            axios
                .get(
                    `?page=${pageNumber}`,
                    config
                )
                .then((response) => {
                    setData(response?.data?.data);
                    setPaginateData(response?.data);
                })
                .catch((error) => { });
        } catch (error) {
            console.log(error);
        }
    };
    if (!(authUser?.support_ticket)) {
        if (authUser?.support_ticket === undefined) {
            return (
                <>
                    <div className="loader-css">
                        <RotatingSquare
                            visible={true}
                            height="100"
                            width="100"
                            color="#4fa94d"
                            ariaLabel="rotating-square-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <h1>unauthorized</h1>
                </>
            );
        }



    } else {
        if (loading) {
            return (
                <>
                    <div className="loader-css" >
                        <RotatingSquare
                            visible={true}
                            height="100"
                            width="100"
                            color="#4fa94d"
                            ariaLabel="rotating-square-loading"
                            wrapperStyle={{}
                            }
                            wrapperClass=""
                        />
                    </div>
                </>
            );
        } else {
    
            return (
                <>
                    <div className="bodywrapper__inner">
    
                        <div className="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
                            <h6 className="page-title">Pending Tickets</h6>
                            <div className="d-flex flex-wrap justify-content-end gap-2 align-items-center breadcrumb-plugins">
                            </div>
                        </div>
    
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card b-radius--10 ">
                                    <div className="card-body p-0">
                                        <div className="table-responsive--sm table-responsive">
                                            <table className="table table--light">
                                                <thead>
                                                    <tr>
                                                        <th>Subject</th>
                                                        <th>Submitted By</th>
                                                        <th>Status</th>
                                                        <th>Priority</th>
                                                        <th>Last Reply</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
    
                                                    {data.length !== 0 ?
                                                        data.map((data, index) => {
                                                            if (data) {
                                                                return (
                                                                    <SingleItem data={data} index={index} key={data._id} paginateData={paginateData}></SingleItem>
                                                                );
                                                            }
                                                        }) :
                                                        <tr>
                                                            <td className="text-muted text-center" colspan="100%">Data not found</td>
                                                        </tr>}
    
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/* paginateLinks */}
                                    <CustomPagination
                                        data={paginateData}
                                        handlePage={handlePage}
                                    > </CustomPagination>
                                    {/* paginateLinks */}
                                </div>
                            </div>
                        </div>
    
    
                    </div>
                </>
            );
        }

    }
   


};

export default SupportTicketPending;